<template>
  <div class="main_content">
    Note Details
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "LNANotes",
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  components: {},
  methods: {
    getNoteDetails() {
      const payload = {
        noteId: this.Payload.noteId,
      };
      this.$store
        .dispatch("getNoteDetails", payload)
        .then((res) => {
          this.Payload = res;
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: error.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    getMaterData(category) {
      let payLoad = {
        matcher: {
          searchString: "",
          //"cntryId": 101,
          //"stateId": 665,
          //"cntryIds": [38], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch("getMaterData", payLoad)
        .then((res) => {
          let dt = res.list;
          //if(category =="llc_type"){
          _.forEach(dt, (obj, index) => {
            dt[index]["selected"] = false;
            this[category].push(dt[index]);
          });

          //  }else{
          //     this[category] = dt;
          //  }
        })
        .catch((err) => {
          this.log(err);
        });
    },
  },
  data: () => ({
    note_access_level: [],

    Payload: {
      title: "",
      comments: [
        /* {"comment": "Ln created12",	"statusId": 2} */
      ],
      document: [],
      description: "",
      taggedTo: [],
      taggedToDetails: [],
      accessLevelId: 1,
    },
  }),
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.getMaterData("note_access_level");
    if (_.has(this.$route.params, "noteId")) {
      this.Payload.noteId = this.$route.params["noteId"];
      this.pageTitle = "Edit Note";
      this.isEdit = true;
      this.getNoteDetails();
    }
  },
  computed: {},
  beforeCreate() {
    this.userdata = this.$store.getters["auth/getuser"];
    // this.userdata = localStorage.getItem('user');
  },
};
</script>
